<template>
  <header>
    <div
      id="image-header"
      class="flex flex-row relative xs:h-32 sm:h-100"
      :style="`background: ${images[activeIndex] ? 'url(' + images[activeIndex].url + ')' : ''} center no-repeat; background-size:cover;`"
    >
      <div class="triangle">
        <div class="absolute triangle--shape xs:left-0 sm:-left-45 md:-left-30 lg:-left-10 xl:left-0"/>
      </div>
      <div class="block sm:hidden triangle-up absolute bottom-0 h-0 w-0 z-10"/>

      <div class="container relative items-center xs:hidden sm:flex">
        <div class="md:w-2/3 xl:w-1/2 z-20">
          <h1 class="header-text">
            <slot name="header-text"/>
          </h1>
          <btn link="/over-examenwerk" class="mt-4"/>
        </div>
        <div class="hidden sm:block triangle-up absolute bottom-0 h-0 w-0 z-10"/>
      </div>
    </div>
    <div class="container relative items-center py-5 xs:flex sm:hidden">
      <div class="md:w-2/3 xl:w-1/2 z-20">
        <h1 class="header-text">
          <slot name="header-text"/>
        </h1>
        <btn link="/over-examenwerk" class="mt-4"/>
      </div>
    </div>
  </header>
</template>

<script>
import Btn from '~/components/base/Btn';

export default {
  components: {Btn},
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeIndex: 0,
      interval: null,
    };
  },
  mounted() {
    if (this.images.length > 1) {
      this.images.forEach(image => this.preloadImage(image.url));

      this.loopImages();
    }
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  methods: {
    preloadImage(url) {
      const img = new Image();
      img.src = url;
    },
    loopImages() {
      this.interval = setInterval(() => {
        if (this.activeIndex < this.images.length - 1) {
          this.activeIndex++;
        } else {
          this.activeIndex = 0;
        }
      }, 5000);
    },
  },
};
</script>

<style lang="sass" scoped>

#image-header
  overflow: hidden
  transition: background-image 0.5s linear
  background-size: 100% auto !important

/deep/ .header-text strong
  color: theme('colors.secondary.default')

// Triangle gradient overlay

.triangle

  &--shape
    background: transparent
    /* comment to see rectangle */ overflow: hidden; /**/
    margin: 0 0 0 -215px
    /* uncomment to see rhombus * outline: solid 1px red; /**/
    width: 38em
    height: 44em
    top: -340%
    transform: rotate(38deg) skewY(52deg)
    @media(min-width: theme('screens.sm'))
      top: -322%
      width: 110.66em
      /* height*0.866 */
      height: 130em

    &:before
      display: block
      /* to be able to apply width/ height/ transform */
      width: inherit
      height: inherit
      transform: skewY(-30deg) rotate(60deg) translate(50%)
      background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, .7))
      background-size: cover
      content: ''

.triangle-up
  border-left: 70px solid transparent
  border-right: 70px solid transparent
  border-bottom: 70px solid white
  @media(min-width: theme('screens.sm'))
    border-left: 200px solid transparent
    border-right: 200px solid transparent
    border-bottom: 170px solid white
</style>
