<template>
  <div>
    <top-header :images="page.images">
      <template slot="header-text">
        <div v-html="page.tekst"/>
      </template>
    </top-header>
  </div>
</template>

<script>
import page from '~/plugins/mixins/page';
import TopHeader from '~/components/TopHeader';

export default {
  components: {
    TopHeader,
  },
  mixins: [page],
};
</script>
